/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}
code {
  color: #E01A76;
}
p{
  margin: 0;
}

.error{
  color: #d6231c;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}
.drop-container{
  margin: 1em;
}

.drop-container .drop-zone {
  border: dashed;
  text-align: center;
  cursor: pointer;
  padding: 1em;
  transition: all 200ms;
}

.drop-container .drop-zone.active {
  border-width: 1px;
}

.image-pool img, form img{
  box-shadow: 0 0 4px 0 rgba(0,0,0,0.5);
  margin: 6px 6px 6px 0;
}

.image-form img{
  margin: 0;
}

.image-pool img.selected{
  border: 4px solid #17a2b8;
}


.image-pool img.selected-sec{
  border: 4px solid #ffc107;
}
.image-pool .delete{
  cursor: pointer;
}
.image-pool .delete .fa-trash{
  margin-right: 2px;
}
.image-pool .delete:hover{
  color: #d6231c;
}

img.small{
  max-width: 50px;
  max-height: 50px;
}


.game-preview-container{
  width: 100%;
  padding: 5px;
}

.game-preview{
  position: relative;
  width: 100%;
  padding-bottom: 62.5%;
  box-shadow: 0 0 5px black;
  overflow: hidden;
  background-size: 100%;
}

.game-preview .text-preview{
  position: absolute;
  white-space: pre;
  -webkit-user-select: none;  /* Chrome all / Safari all */
  -moz-user-select: none;     /* Firefox all */
  -ms-user-select: none;      /* IE 10+ */
  user-select: none;          /* Likely future */
  line-height: normal;
}

.line-height-normal{
  line-height: normal;
}

.btn-group-custom .btn{
  margin-right: 5px;
}


.gameflow-table .active{
  color: darkcyan;
  background: rgba(0,255,0,0.2);
}

.nav-link.active{
  text-decoration: underline;
}

.audio-control{
  margin-right: 5px;
}

.selected-game.game-card{
  margin: 0 0 5px 0;
}

.game-card{
  margin: 5px;
}
.game-choose-modal .game-card:hover{
  box-shadow: 0 0 5px #0e701d;
}
.game-choose-modal{
  max-width: 80vw;
}

.game-choose-modal .game-card{
  cursor: pointer;
}

img.active{
  box-shadow: 0 0 10px #0e701d;
}

@font-face {
  font-family: "Abraham";
  src: url("/fonts/Abraham-Regular.ttf");
}
@font-face {
  font-family: "Comix No2 CLM";
  src: url("/fonts/comixno2.ttf");
}
@font-face {
  font-family: "Dana Yad AlefAlefAlef";
  src: url("/fonts/DanaYadAlefAlefAlef-Normal.otf");
}
@font-face {
  font-family: "Ellinia CLM";
  src: url("/fonts/elliniaclm-bold-webfont.ttf");
}
@font-face {
  font-family: "Gan CLM";
  src: url("/fonts/ganclm_bold-webfont.ttf");
}
@font-face {
  font-family: "Noot";
  src: url("/fonts/noot-aj.ttf");
}
@font-face {
  font-family: "Secular One";
  src: url("/fonts/SecularOne.ttf");
}
@font-face {
  font-family: "Simple CLM";
  src: url("/fonts/simpleclm-medium-webfont.ttf");
}
@font-face {
  font-family: "Trashim CLM";
  src: url("/fonts/trashimclm-bold-webfont.ttf");
}
@font-face {
  font-family: "Yehuda CLM";
  src: url("/fonts/yehudaclm-bold-webfont.ttf");
}